/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { configureStore } from "@reduxjs/toolkit";

import { userReducer, UserState } from "admin/user/user.store";
import { authReducer, AuthState } from "auth/auth.store";
import { branchReducer, BranchState } from "branch/branch.store";
import { businessReducer, BusinessState } from "business/business.store";
import { catalogReducer, CatalogState } from "catalog/catalog.store";
import { categoryReducer, CategoryState } from "category/category.store";
import { commonReducer, CommonState } from "common/common.store";
import { ApiClient } from "common/utils";
import { directoryReducer, DirectoryState } from "directory/directory.store";
import { employeeReducer, EmployeeState } from "employee/employee.store";
import { geoReducer, GeoState } from "geo/geo.store";
import { mediaReducer, MediaState } from "media/media.store";
import { merchantReducer, MerchantState } from "merchant/merchant.store";
import { orderReducer, OrderState } from "order/order.store";
import { productReducer, ProductState } from "product/product.store";
import { reportReducer, ReportState } from "report/report.store";
import { subscriptionReducer, SubscriptionState } from "subscription/subscription.store";
import { tableReducer, TableState } from "table/table.store";


export interface ApiState {
  admin: {
    user: UserState,
  },
  common: CommonState,
  geo: GeoState,
  auth: AuthState,
  business: BusinessState,
  branch: BranchState,
  catalog: CatalogState,
  product: ProductState,
  category: CategoryState,
  media: MediaState,
  dictionary: DirectoryState,
  subscription: SubscriptionState,
  merchant: MerchantState,
  order: OrderState,
  employee: EmployeeState,
  table: TableState,
  report: ReportState,
  prev?: ApiState,
}

export default function createStore(api: ApiClient) {
  const store = configureStore<ApiState>({
    reducer: (state, action) => {
      return {
        admin: {
          user: userReducer(state?.admin.user, action),
        },
        common: commonReducer(state?.common, action),
        geo: geoReducer(state?.geo, action),
        auth: authReducer(state?.auth, action),
        media: mediaReducer(state?.media, action),
        business: businessReducer(state?.business, action),
        branch: branchReducer(state?.branch, action),
        catalog: catalogReducer(state?.catalog, action),
        product: productReducer(state?.product, action),
        category: categoryReducer(state?.category, action),
        dictionary: directoryReducer(state?.dictionary, action),
        subscription: subscriptionReducer(state?.subscription, action),
        merchant: merchantReducer(state?.merchant, action),
        order: orderReducer(state?.order, action),
        employee: employeeReducer(state?.employee, action),
        table: tableReducer(state?.table, action),
        report: reportReducer(state?.report, action),
        prev: _.omit(state, "prev"),
      };
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      thunk: {
        extraArgument: api,
      },
      serializableCheck: false,
    }) as any,
  });
  api.attachStore(store);
  return store;
}