/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LuPackageOpen } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

import {
	BackAsyncError, Backout, Button, Callout, Grid, GridContentLoader
} from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import { ApiCallStateBuilder } from "common/utils";
import OrderCard from "order/components/OrderCard";
import { Order } from "order/order.entities";
import { useSyncOrders } from "order/order.hooks";
import { doesOrderMeetSearchQuery } from "order/utils/order-search-utils";


export default function () {
  const navigate = useNavigate();
  const { t } = useTranslation(undefined, { keyPrefix: "Order.ListPage" })

  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const { syncOrdersState, orders } = useSyncOrders({
    auto: false, // triggered from WorkspaceLayout, otherwise double "sync"
    filter: (order) => _.isEmpty(searchQuery) || doesOrderMeetSearchQuery(order, searchQuery!)
  }, [searchQuery]);

  return (
    <>
      <PageHeader
        icon={<LuPackageOpen size={23} style={{ marginInlineEnd: 1, marginTop: -1 }} />}
        title={['Order.ListPage.header']}
        onSearchQueryChange={setSearchQuery} >
        <Button
          text={["create"]}
          intent="primary"
          onClick={() => navigate('/orders/pickup/new')}
        />
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder<Order[]>
          state={syncOrdersState}
          orElse={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
          onError={(error) => <BackAsyncError error={error} />}
          onValue={(_orders) => {
            if (_orders.length === 0) {
              return (
                <>
                  <Callout intent='primary' style={{ marginBottom: 30 }}>
                    <>
                      <p>{t("calloutMainText")}</p>
                      {t("calloutFbaBeforeLinkText")}
                      <a href='https://dl.dishdash.io/dt/naxz' target='_blan'>
                        {t("calloutFbaLinkText")}
                      </a>
                    </>
                  </Callout>
                  <div style={{ marginTop: 150 }}>
                    <Backout
                      icon={<LuPackageOpen style={{ marginTop: -3, marginInlineStart: -2 }} />}
                      title={["Order.ListPage.noOrdersYetBackoutMsg"]}>
                      <div style={{ marginTop: 20 }}>
                        <Button
                          outlined
                          text={["create"]}
                          onClick={() => navigate("/orders/pickup/new")} />
                      </div>
                    </Backout>
                  </div>
                </>
              );
            }
            return (
              <Grid md={3} sm={2} xs={1} gap={20} >
                {orders.map(order =>
                  <OrderCard
                    key={order.id}
                    value={order}
                    onClick={() => navigate('/orders/' + order.type + '/' + order.id!)}
                  />
                )}
              </Grid>
            )
          }}
        />
      </PageContent>
    </>
  );
}
