/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
	ApiAction, DeleteListItemOperation, failed, loading, success
} from "common/utils";
import { Order } from "order/order.entities";

import { CancelOrderState, SyncOrdersState } from "../order.store";


export function CancelOrder(id: string): ApiAction {
  return async (dispatch, store, api) => {
    dispatch(CancelOrderState(loading()));
    try {
      const { value } = await api.delete(`/orders/${id}`);
      dispatch(CancelOrderState(success(value)));
    } catch (error) {
      dispatch(CancelOrderState(failed(error)));
    }
  };
}

function test(a: Order, b: Order) {
  return a.id === b.id;
}