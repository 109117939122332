/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Button } from "@blueprintjs/core";
import { IItemRendererProps, ItemPredicate, Select2, Select2Props } from "@blueprintjs/select";
import { useMemo } from "react";

import { OnChangeEventHandler } from "common/utils";
import FormField, { FormFieldProps } from "../Field";
import styles from "./styles.module.sass";


export default function SelectField<T extends { id?: string }>({
  className, label, hidden, disabled, error, name, items,
  itemPredicate, readOnly, value, onChange, buttonRenderer,
  popoverProps, ...rest
}: SelectFieldProps<T>) {
  const classes = useMemo(() => {
    const cls = [styles.selectField, className];
    if (readOnly) cls.push(styles.readOnly);
    return cls;
  }, [className, readOnly]);

  const selectedItem = items.find(i => i?.id === value?.id);

  return (
    <FormField
      className={classes.join(" ")}
      label={label}
      disabled={disabled}
      hidden={hidden}
      error={error} >
      <Select2<T>
        items={items}
        activeItem={selectedItem}
        filterable={itemPredicate != null}
        itemPredicate={itemPredicate}
        disabled={readOnly || disabled}
        intent={error ? "danger" : undefined}
        onItemSelect={(value: T) => {
          if (onChange)
            onChange({ target: { name, value } });
        }}
        popoverProps={{
          minimal: true,
          matchTargetWidth: true,
          popoverClassName: styles.popover,
          usePortal: true,
          ...popoverProps
        }}
        {...rest}>
        <Button className={selectedItem ? undefined : styles.placeholder}>
          {buttonRenderer(selectedItem)}
        </Button>
      </Select2>
    </FormField>
  );
}


export type NullableItemRenderer = (props: IItemRendererProps) => JSX.Element;

export type SelectFieldProps<T extends { id?: string }> = Omit<FormFieldProps, "children"> &
  Omit<Select2Props<T>, | "items" | "onItemSelect" | "onChange" |
    "filterable" | "itemPredicate" | "createNewItemRenderer"> & {
      name: string,
      items: T[],
      value?: { id?: string } | null,
      onChange?: OnChangeEventHandler<T>,
      readOnly?: boolean,
      buttonRenderer: (item?: T) => JSX.Element | string,
      itemPredicate?: ItemPredicate<T>,
    }