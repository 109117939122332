/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import moment from 'moment';
import 'react-infinite-calendar/styles.css';

import { useMemo } from 'react';
import InfiniteCalendar, {
  Calendar,
  defaultMultipleDateInterpolation,
  withMultipleDates,
} from 'react-infinite-calendar';

import { Tag } from 'common/components/base';
import { OnChangeEventHandler } from 'common/utils';
import styles from "./styles.module.scss";


export default function ({
  className, name, value, onChange, readOnly,
  children, ...rest
}: DayPicker) {
  const classes = useMemo(() => [className].join(" "), [className]);

  const IntiniteCalendarComponent = useMemo(() => withMultipleDates(Calendar), []);
  const valueAsDates = useMemo(() => {
    const arr = value.map(day => moment().startOf('year').add(day - 1, 'days'));
    arr.sort((a, b) => a.unix() - b.unix());
    return arr;
  }, [value]);

  return (
    <div className={classes}>
      <InfiniteCalendar
        Component={IntiniteCalendarComponent}
        className={styles.calendar}
        width={400}
        height={350}
        displayOptions={{
          showHeader: false,
          showWeekdays: false,
          showTodayHelper: false,
        }}
        selected={valueAsDates.map(d => d.toDate())}
        interpolateSelection={defaultMultipleDateInterpolation as any}
        min={moment().startOf('year').toDate()}
        max={moment().endOf('year').toDate()}
        onSelect={onChange ? (date: Date) => {
          const dayOfYear = moment(date).dayOfYear();
          const idx = value.indexOf(dayOfYear);
          if (idx == -1) {
            const newValue = [...value, dayOfYear];
            onChange({ target: { name: name || '', value: newValue } });
          } else {
            const newValue = [...value];
            newValue.splice(idx, 1);
            onChange({ target: { name: name || '', value: newValue } });
          }
        } : undefined}
      />
      <div className="tagList">
        {valueAsDates.map(d =>
          <Tag
            key={d.toISOString()}
            onRemove={onChange ? () => {
              const dayOfYear = moment(d).dayOfYear();
              const idx = value.indexOf(dayOfYear);
              const newValue = [...value];
              newValue.splice(idx, 1);
              onChange({ target: { name: name || '', value: newValue } });
            } : undefined} >
            {d.format('MMMM Do')}
          </Tag>
        )}
      </div>
    </div>
  );
}

export type DayPicker = {
  name?: string;
  className?: string;
  value: number[];
  error?: string | [string, object?, string?],
  onChange?: OnChangeEventHandler<number[]>;
  readOnly?: boolean;
  children?: React.ReactNode;
};