import _ from "lodash";

import { AlertProps, Icon } from "@blueprintjs/core";
/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import Decimal from "decimal.js";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useSyncBranches } from "branch/branch.hooks";
import { CreateCartItem, CreateManualCartItem } from "catalog/catalog.entities";
import CartItemCard from "catalog/components/CartItemCard";
import OptionPickerForm from "catalog/components/OptionPickerForm";
import {
	Alert, BackActivityIndicator, BackAsyncError, Backout, Button, Callout,
	Card, CardContent, CardHeader, Column, DropdownField, ExtPhoneField, Form,
	FormField, Grid, NumericField, PageContent, PageHeader, Row, SelectField,
	TextInputField, Toast
} from "common/components";
import {
	ApiCallStateBuilder, failed, isLoading, isSuccessful, useApiErrors,
	useApiState, useFormData, useInitial
} from "common/utils";
import { preparePickupOrder } from "order/actions/prepare-pickup-order.action";
import { PreparePickupOrder } from "order/order.entities";
import { PreparePickupOrderState } from "order/order.store";
import ProductCard from "product/components/ProductCard";
import { Product, ProductOption } from "product/product.entities";
import { useSyncProducts } from "product/product.hooks";

import styles from "./styles.module.scss";


export default function () {
  const initial = useInitial();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [prepareState, dispatch] = useApiState((store) => store.order.preparePickupOrder);
  const { syncBranchesState, branches } = useSyncBranches();

  // form data
  const { formData, onChange, formErrors, changes, setFormErrors } =
    useFormData<PreparePickupOrder>(null, {
      branch: branches?.at(0)?.id,
      cart: {
        items: [],
        totalPrice: 0,
      },
    }, [syncBranchesState.value]);
  useApiErrors(prepareState, setFormErrors);
  const [minSelectedGroupErrors, setMinSelectedGroupErrors] = useState<string[]>([]);

  // api state
  const { syncProductsState, products } = useSyncProducts({
    filter: (product) => product.available,
  })

  // local state
  const [isConfirmingCreation, confirmCreation] = useState(false);
  const [isProductPickerOpen, setProductPickerOpen] = useState(false);
  const [isManualItemDialogVisible, showManualItemDialog] = useState(false);

  // effects
  useEffect(() => {
    if (!initial) {
      if (isSuccessful(prepareState)) {
        navigate(pathname.replace("/new", `/${prepareState.value!.id}`), { replace: true });
        Toast.showSuccess({ message: ["Order.ItemPage.addedToast"] });
      }
    }
  }, [prepareState]);

  useEffect(() => {
    let sum = new Decimal(0);
    formData.cart.items.forEach(item => {
      if (_.isString(item.product)) {
        const product = products.find(p => p.id === item['product']);
        if (!product) {
          Toast.showDanger({
            message: ["Order.PhoneOrderPage.productNotAvailableToast", { item }],
            action: {
              text: t('reload'),
              onClick: () => window.location.reload(),
            }
          })
        } else {
          sum = sum.add(new Decimal(product.price).mul(new Decimal(item.quantity)));
          item['options']?.forEach(optionId => {
            product.optionGroups?.forEach(group => {
              const option = group.options.find(option => option.id == optionId);
              if (option)
                sum = sum.add(new Decimal(option.price).mul(new Decimal(item.quantity)));
            })
          });
        }
      } else {
        sum = sum.add(new Decimal(item.product.price).mul(new Decimal(item.quantity)));
      }
    });
    const totalPrice = sum.toNearest(0.05).toNumber();
    if (totalPrice != formData.cart.totalPrice) {
      onChange({
        target: {
          name: 'cart.totalPrice',
          value: totalPrice,
        }
      })
    }
  }, [formData.cart, products])


  const validateAndCreate = useCallback(() => {
    const groupMinSelectedErrors: string[] = [];
    if (_.isEmpty(formData.customerEmail) && _.isEmpty(formData.customerPhone?.number)) {
      return dispatch(PreparePickupOrderState(
        failed({
          code: "constraint-error",
          constraints: {
            customerPhone: {
              number: ["isExtPhone"],
            },
          }
        })
      ))
    }
    if (formData.cart.items.length === 0) {
      return dispatch(PreparePickupOrderState(
        failed({
          code: "constraint-error",
          constraints: {
            cart: {
              items: ["arrayMinSize", { count: 1 }],
            },
          }
        })
      ))
    }
    for (const item of formData.cart.items) {
      const product = products.find(p => p.id === item['product']);
      if (product?.optionGroups?.length) {
        for (const group of product.optionGroups) {
          const selectedInGroup =
            group.options.filter(option => item['options']?.includes(option.id) === true);
          if (selectedInGroup.length < group.minSelected) {
            groupMinSelectedErrors.push(group.id)
          }
        }
      }
    }
    if (groupMinSelectedErrors.length) {
      setMinSelectedGroupErrors(groupMinSelectedErrors);
    } else {
      confirmCreation(true);
    }
  }, [formData.cart.items]);

  return (
    <>
      <PageHeader
        title={["Order.PhoneOrderPage.header"]}
        backButtonPath="/orders">
        <Button
          text={["create"]}
          intent="primary"
          onClick={validateAndCreate}
          hidden={_.isEmpty(changes)}
          loading={isLoading(prepareState)}
        />
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder state={syncBranchesState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} />}
          onSuccess={() =>
            <ApiCallStateBuilder state={syncProductsState}
              onLoading={() => <BackActivityIndicator />}
              onError={(error) => <BackAsyncError error={error} />}
              onValue={() => {
                return (
                  <Grid md={2} xs={1} gap={20}>
                    <Card>
                      <CardHeader title={["general"]} />
                      <CardContent>
                        <Form >
                          <DropdownField
                            nullable={formData.branch == null}
                            name="branch"
                            label={["branch"]}
                            value={formData.branch}
                            error={formErrors?.branch}
                            options={branches.map(item => ({
                              id: item.id!,
                              value: item.id,
                              name: item.name,
                            }))}
                            onChange={onChange}
                          />
                          <Row className={styles.customerRow}>
                            <ExtPhoneField
                              name="customerPhone"
                              label={["customer"]}
                              placeholder={["phoneNumber"]}
                              value={formData.customerPhone}
                              error={formErrors?.customerPhone}
                              onChange={(e) => {
                                onChange(e, {
                                  target: {
                                    name: 'customerEmail',
                                    value: null
                                  }
                                });
                              }}
                            />
                            <span>OR</span>
                            <TextInputField
                              maxLength={50}
                              name="customerEmail"
                              placeholder={["email"]}
                              value={formData.customerEmail}
                              error={formErrors?.customerEmail}
                              onChange={(e) => {
                                onChange(e, {
                                  target: {
                                    name: 'customerPhone',
                                    value: null
                                  }
                                });
                                if (formErrors?.customerPhone) {
                                  setFormErrors({ ...formErrors, customerPhone: null });
                                }
                              }}
                            />
                          </Row>
                        </Form>
                      </CardContent>
                    </Card>
                    <Card>
                      <CardHeader
                        title={["cart"]}
                        left={
                          <Button
                            minimal
                            icon="manually-entered-data"
                            onClick={() => showManualItemDialog(true)}
                          />
                        }
                        right={
                          <span className={styles.totalPrice}>
                            {formData.cart.totalPrice.toFixed(2).padEnd(2, '0')}
                          </span>
                        }
                      />
                      <CardContent className={styles.cartItemCards}>
                        <SelectField<Product>
                          name="cart.items"
                          error={formErrors?.cart?.items}
                          value={undefined}
                          items={products}
                          popoverProps={{
                            isOpen: isProductPickerOpen,
                            onInteraction: setProductPickerOpen,
                          }}
                          onChange={(event) => {
                            const newItems = formData.cart.items.slice();
                            const idx = newItems.findIndex(item =>
                              item["product"] === event.target.value.id);
                            if (idx >= 0) {
                              newItems[idx] = {
                                ...newItems[idx],
                                quantity: newItems[idx].quantity + 1,
                              }
                            } else {
                              newItems.push({
                                product: event.target.value.id!,
                                quantity: 1,
                              });
                            }
                            onChange({
                              target: {
                                name: event.target.name,
                                value: newItems,
                              }
                            })
                          }}
                          buttonRenderer={() => t("Order.PhoneOrderPage.addProduct")}
                          itemPredicate={(query, product) =>
                            _.values(product).join(' ').toLowerCase().includes(query.toLowerCase())
                          }
                          itemRenderer={(product, props) => {
                            const cartItem = formData.cart.items.find(item =>
                              item["product"] === product.id);
                            return (
                              <ProductCard
                                key={product.id}
                                className={styles.selectProductCard}
                                value={product}
                                onClick={props.handleClick}
                                onFocus={props.handleFocus}
                                tag={cartItem == null ? undefined :
                                  <span className='quantity'>+{cartItem?.quantity} </span>
                                } />
                            );
                          }} />
                        {formData.cart.items.length === 0
                          ? <Backout message={["Order.PhoneOrderPage.noItemsYet"]} />
                          : <>
                            {formData.cart.items
                              .filter(item => _.isString(item.product))
                              .map(item => item as CreateCartItem)
                              .map((item, itemIndex) => {
                                const product = products.find(p => p.id === item.product)!;
                                const options: ProductOption[] = [];
                                item.options?.forEach(id =>
                                  product.optionGroups?.forEach(group => {
                                    const option = group.options.find(option => option.id == id);
                                    if (option)
                                      options.push(option);
                                  }));
                                return (
                                  <Row key={itemIndex} mainAxis='space-between' crossAxis='center'>
                                    <CartItemCard
                                      interactive={false}
                                      value={{
                                        product: product,
                                        options,
                                        quantity: item.quantity,
                                      }}>
                                      <Form className={styles.optionGroups}>
                                        {product.optionGroups?.map(group => (
                                          <FormField
                                            key={group.id}
                                            collapsed={group.minSelected === 0}
                                            label={group.name}
                                            error={
                                              minSelectedGroupErrors.includes(group.id) === false ? undefined :
                                                t("Constraints.minSelected", { count: group.minSelected })
                                            }>
                                            <OptionPickerForm
                                              name={`cart.items.${itemIndex}.options`}
                                              group={group}
                                              value={item.options}
                                              onChange={(e) => {
                                                if (minSelectedGroupErrors.includes(group.id)) {
                                                  setMinSelectedGroupErrors(minSelectedGroupErrors.filter(id => group.id !== id));
                                                }
                                                onChange(e);
                                              }}
                                            />
                                          </FormField>
                                        ))}
                                      </Form>
                                    </CartItemCard>
                                    <Column className={styles.productActions}>
                                      <Button
                                        minimal
                                        icon="share"
                                        onClick={() => window.open(`/products/${product.id}`, "_blank")}
                                      />
                                      <Button
                                        minimal
                                        icon="add"
                                        onClick={() => {
                                          const newItems = formData.cart.items.slice();
                                          const idx = newItems.findIndex(i => i['product'] === item.product);
                                          newItems[idx] = {
                                            ...newItems[idx],
                                            quantity: newItems[idx].quantity + 1,
                                          }
                                          onChange({
                                            target: {
                                              name: "cart.items",
                                              value: newItems,
                                            }
                                          })
                                        }}
                                      />
                                      <Button
                                        minimal
                                        icon="remove"
                                        onClick={() => {
                                          const newItems = formData.cart.items.slice();
                                          const idx = newItems.findIndex(i => i['product'] === item.product);
                                          if (newItems[idx].quantity === 1) {
                                            newItems.splice(idx, 1);
                                          } else {
                                            newItems[idx] = {
                                              ...newItems[idx],
                                              quantity: newItems[idx].quantity - 1,
                                            }
                                          }
                                          onChange({
                                            target: {
                                              name: "cart.items",
                                              value: newItems,
                                            }
                                          })
                                        }}
                                      />
                                    </Column>
                                  </Row>
                                )
                              })}
                            {formData.cart.items
                              .filter(item => _.isObject(item.product))
                              .map(item => item as CreateManualCartItem)
                              .map((item, itemIndex) => {
                                return (
                                  <Row key={itemIndex} mainAxis='space-between' crossAxis='center'>
                                    <CartItemCard
                                      interactive={false}
                                      value={{
                                        product: item.product,
                                        quantity: item.quantity,
                                      }}>
                                    </CartItemCard>
                                    <Column className={styles.productActions}>
                                      <Button
                                        minimal
                                        icon="add"
                                        onClick={() => {
                                          const newItems = formData.cart.items.slice();
                                          const idx = newItems.findIndex(i => i['product'] === item.product);
                                          newItems[idx] = {
                                            ...newItems[idx],
                                            quantity: newItems[idx].quantity + 1,
                                          }
                                          onChange({
                                            target: {
                                              name: "cart.items",
                                              value: newItems,
                                            }
                                          })
                                        }}
                                      />
                                      <Button
                                        minimal
                                        icon="remove"
                                        onClick={() => {
                                          const newItems = formData.cart.items.slice();
                                          const idx = newItems.findIndex(i => i['product'] === item.product);
                                          if (newItems[idx].quantity === 1) {
                                            newItems.splice(idx, 1);
                                          } else {
                                            newItems[idx] = {
                                              ...newItems[idx],
                                              quantity: newItems[idx].quantity - 1,
                                            }
                                          }
                                          onChange({
                                            target: {
                                              name: "cart.items",
                                              value: newItems,
                                            }
                                          })
                                        }}
                                      />
                                    </Column>
                                  </Row>
                                )
                              })}
                          </>}
                      </CardContent>
                    </Card>
                  </Grid>
                );
              }}
            />
          }
        />
        <Alert
          isOpen={isConfirmingCreation}
          confirmButtonText={['create']}
          cancelButtonText={['nope']}
          icon="cube-add"
          intent="primary"
          loading={prepareState.isLoading}
          onCancel={() => confirmCreation(false)}
          onConfirm={() => {
            confirmCreation(false);
            dispatch(preparePickupOrder(formData));
          }}>
          <p>{t("Order.PhoneOrderPage.createDialogBody")}</p>
        </Alert>
        <ManualItemDialog
          isOpen={isManualItemDialogVisible}
          onCancel={() => showManualItemDialog(false)}
          onAdd={(name, price, sku) => {
            const newItem: CreateManualCartItem = {
              product: { name, price, sku },
              quantity: 1
            }
            const newItems = formData.cart.items.slice();
            newItems.push(newItem);
            onChange({
              target: {
                name: "cart.items",
                value: newItems,
              }
            })
            showManualItemDialog(false);
          }} />
      </PageContent >
    </>
  );
}

function ManualItemDialog(
  props: AlertProps & {
    onAdd: (name: string, price: number, sku?: string) => void,
  }
) {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [price, setPrice] = useState<number | undefined>(undefined);
  const [sku, setSku] = useState<string | undefined>(undefined);
  const [nameError, setNameError] = useState('');
  const [priceError, setPriceError] = useState('');

  return (
    <Alert
      confirmButtonText={['add']}
      cancelButtonText={['cancel']}
      // icon="manually-entered-data"
      intent="primary"
      {...props}
      onConfirm={() => {
        if (_.isEmpty(name)) {
          return setNameError(t("required"))
        }
        if (price && price <= 0.0) {
          return setPriceError(t("Order.PhoneOrderPage.invalidPrice"))
        }
        props.onAdd(name, price || 0.0, sku);
      }}>
      <Form style={{ marginTop: -10, marginBottom: 30, marginInlineStart: 10, marginInlineEnd: 10 }}>
        <h4 style={{ marginBottom: 20 }}>
          <Icon icon='manually-entered-data' />
          {t("Order.PhoneOrderPage.manualItemDialogHeader")}
        </h4>
        <Callout intent='primary' style={{ marginBottom: 20 }}>
          {["Order.PhoneOrderPage.manualItemDialogCallout"]}
        </Callout>
        <TextInputField
          maxLength={25}
          name={"name"}
          label={["name"]}
          value={name}
          error={nameError}
          onChange={({ target: { value } }) => {
            setNameError('');
            setName(value);
          }}
        />
        <NumericField
          className={styles.customItemPriceField}
          name={"price"}
          label={["price"]}
          value={price}
          error={priceError}
          onChange={({ target: { value } }) => {
            if (value) {
              setPriceError('');
              setPrice(value);
            }
          }}
          stepSize={.1}
          majorStepSize={1}
          minorStepSize={.01}
        />
        <TextInputField
          maxLength={15}
          hidden={true}
          name={"sku"}
          label={["sku"]}
          placeholder={["optional"]}
          value={sku ?? ''}
          onChange={({ target: { value } }) => {
            setSku(value);
          }}
        />
      </Form>
    </Alert>
  )
}