/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";
import moment from "moment";

import { Popover2 } from "@blueprintjs/popover2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiStore, BiWallet } from "react-icons/bi";
import { MdOutlineTableBar } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import CartItemCard from "catalog/components/CartItemCard";
import {
	Alert, BackActivityIndicator, BackAsyncError, Button, Callout, Card,
	CardContent, CardHeader, CardItem, Grid, Menu, MenuItem, NumericField,
	PageContent, PageHeader, PageNotFound, Toast
} from "common/components";
import {
	ApiCallState, ApiCallStateBuilder, formatExPhone, isUseless, useApiState,
	useInitial
} from "common/utils";
import { CancelOrder } from "order/actions/cancel-order.action";
import { CompleteOrder } from "order/actions/complete-order.action";
import { ConfirmOrder } from "order/actions/confirm-order.action";
import { EditEtrAction } from "order/actions/edit-order.action";
import { loadOrder as loadOrderAction } from "order/actions/load-order.action";
import OrderPaymentTag from "order/components/OrderPaymentTag";
import OrderStatusTag from "order/components/OrderStatusTag";
import { DineInOrder } from "order/order.entities";
import { useCreatedAgo, useDefaultEtr, useEtrString } from "order/order.hooks";

import styles from "./styles.module.scss";


export default function () {
  const { id } = useParams();
  const { t } = useTranslation();
  const initial = useInitial();
  const navigate = useNavigate();
  const { state } = useLocation();

  // api state
  const [loadOrderStateMap] = useApiState((store) => store.order.loadOrderMap);
  const [cancelState, dispatch] = useApiState((store) => store.order.cancel);
  const [confirmState] = useApiState((store) => store.order.confirm);
  const [completeState] = useApiState((store) => store.order.complete);
  const loadOrderState = (loadOrderStateMap[id!] || {}) as ApiCallState<DineInOrder>;
  const order = loadOrderState.value;
  const loadOrder = () => dispatch(loadOrderAction(id!))

  // page state
  const [isCancellationDialogVisible, showCancallationDialog] = useState(false);
  const [isConfirmationDialogVisible, showConfirmationDialog] = useState(false);
  const [isEditEtrDialogVisible, showEditEtrDialog] = useState(false);
  const [isCompleteDialogVisible, showCompleteDialog] = useState(false);
  const [etr, setEtr] = useDefaultEtr(order);

  useEffect(() => {
    if (isUseless(loadOrderState))
      loadOrder();
  }, [id, loadOrderState]);

  // handle cancel state
  useEffect(() => {
    if (!initial) {
      if (cancelState.value) {
        Toast.showSuccess({
          message: ["Order.ItemPage.cancelledToast", { entity: cancelState.value }],
        });
        navigate('/orders');
      } else if (cancelState.error) {
        Toast.showApiError(cancelState.error);
      }
    }
  }, [cancelState]);

  // handle confirm state
  useEffect(() => {
    if (!initial) {
      if (confirmState.error) {
        Toast.showApiError(confirmState.error);
      } else if (confirmState.value) {
        showConfirmationDialog(false);
      }
    }
  }, [confirmState]);

  // handle complete state
  useEffect(() => {
    if (!initial) {
      if (completeState.error) {
        Toast.showApiError(completeState.error);
      } else if (completeState.value) {
        showCompleteDialog(false);
      }
    }
  }, [completeState]);

  const createdAgo = useCreatedAgo(order);
  const etrString = useEtrString(order);

  return (
    <>
      <PageHeader
        title={order?.number}
        backButtonPath={state?.backpath || "/orders"}>
        <Button
          text={["confirm"]}
          intent="success"
          onClick={() => {
            showConfirmationDialog(true);
          }}
          hidden={!order || order.status !== 'created'}
        />
        <Button
          text={["Order.ItemPage.editEtrButton"]}
          intent="primary"
          onClick={() => {
            showEditEtrDialog(true);
          }}
          hidden={!order || !['active', 'created'].includes(order.status) || !order.etr}
        />
        <Popover2
          placement="bottom-end"
          interactionKind="click"
          content={
            <Menu>
              <MenuItem
                icon='issue-closed'
                text={["complete"]}
                intent="none"
                onClick={() => {
                  showCompleteDialog(true);
                }}
                hidden={!order || order.status !== 'active'}
              />
              <MenuItem
                icon="cross-circle"
                text={["cancel"]}
                intent="danger"
                onClick={() => showCancallationDialog(true)} />
            </Menu>
          }>
          <Button
            minimal
            rightIcon="menu"
            hidden={[
              'pending',
              'prepared',
              'created',
              'active',
            ].includes(order?.status || 'none') === false}
          />
        </Popover2>
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder state={loadOrderState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} onTryAgain={loadOrder} />}
          onValueNull={() => <PageNotFound />}
          onValue={(order) => (
            <Grid md={2} xs={1} gap={20}>
              <Card>
                <CardContent>
                  <CardItem
                    icon="application"
                    text={["status"]}
                    right={
                      <OrderStatusTag order={order} />
                    }
                  />
                  <CardItem
                    icon='calendar'
                    text={["createdAt"]}
                    right={
                      <span>
                        {moment(order.createdAt).local().format(t("Formats.time"))}, {createdAgo}
                      </span>
                    }
                  />
                  {order.etr != null && (
                    <CardItem
                      icon='time'
                      text={["etr"]}
                      right={
                        <span>
                          {moment(order.etr).local().format(t("Formats.time"))}, {etrString}
                        </span>
                      }
                    />
                  )}
                  <CardItem
                    icon={<BiStore size={18} style={{ marginBottom: -4, marginInlineStart: -1, marginInlineEnd: -2 }} />}
                    text={["branch"]}
                    right={
                      <a href={`/branches/${order.branch.id}`} target='_blank'>
                        {order.branch.name}
                      </a>
                    }
                  />
                  <CardItem
                    icon={<MdOutlineTableBar size={18} style={{ marginBottom: -4, marginInlineEnd: -2 }} />}
                    text={["table"]}
                    right={order.table}
                  />
                  <CardItem
                    icon="user"
                    text={["customer"]}
                    right={
                      <>
                        {order.customer.phone ? (
                          <a href={`tel:${formatExPhone(order.customer.phone)}`}>
                            {formatExPhone(order.customer.phone)}
                          </a>
                        ) : (
                          <a href={`mailto:${order.customer.email}`}>
                            {order.customer.email}
                          </a>
                        )}
                        {order.customer.name ? ` (${order.customer.name})` : ''}
                      </>
                    } />
                  <CardItem
                    icon={<BiWallet size={18} style={{ marginBottom: -2 }} />}
                    text={["payment"]}
                    right={<OrderPaymentTag order={order} />} />
                  <CardItem
                    icon="dollar"
                    text={["totalPrice"]}
                    right={order.cart.totalPrice + ' ' + order.payment!.merchant.currency} />
                  <CardItem
                    icon="info-sign"
                    text={["specialInstractions"]}
                    hidden={_.isEmpty(order.note)}
                    right={<span className={styles.note}>{order.note}</span>} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader
                  title={["cart"]}
                  right={
                    <span className={styles.totalPrice}>
                      {order.cart.totalPrice.toFixed(2).padEnd(2, '0')}
                      &nbsp;{order.payment!.merchant.currency}
                    </span>
                  }
                />
                <CardContent className={styles.cartItemCards}>
                  {order.cart.items
                    .map((item) => {
                      return (
                        <CartItemCard
                          key={item.product.id}
                          interactive={false}
                          value={item}
                          onClick={() => {
                            if (item.product.id) {
                              window.open('/products/' + item.product.id, "_blank");
                            } else {
                              Toast.showPrimary({
                                message: ["Order.ItemPage.manualItemToast", { item }],
                                timeout: 900,
                              });
                            }
                          }}
                        />
                      )
                    })}
                </CardContent>
              </Card>
            </Grid>
          )}
        />
        <Alert
          isOpen={isCancellationDialogVisible}
          confirmButtonText={['cancel']}
          cancelButtonText={['nope']}
          icon="cross-circle"
          intent="danger"
          loading={cancelState.isLoading}
          onCancel={() => showCancallationDialog(false)}
          onConfirm={() => dispatch(CancelOrder(id!))}>
          <p>{t("Order.ItemPage.cancelDialogBody")}</p>
        </Alert>
        <Alert
          isOpen={isCompleteDialogVisible}
          confirmButtonText={['complete']}
          cancelButtonText={['nope']}
          icon="issue-closed"
          intent="none"
          loading={completeState.isLoading}
          onCancel={() => showCompleteDialog(false)}
          onConfirm={() => dispatch(CompleteOrder(id!))}>
          <>
            <p>{t("Order.ItemPage.completeDialogBody")}</p>
            {order?.payment?.method?.offline && (
              <Callout intent='warning' style={{ marginTop: 20, marginBottom: 10 }}>
                {["Order.ItemPage.completeDialogOfflinePaymentCallout"]}
              </Callout>
            )}
          </>
        </Alert>
        <Alert
          isOpen={isConfirmationDialogVisible}
          confirmButtonText={['confirm']}
          cancelButtonText={['nope']}
          icon="endorsed"
          intent="success"
          loading={confirmState.isLoading}
          onCancel={() => showConfirmationDialog(false)}
          onConfirm={() => {
            if (etr < 0) {
              Toast.showDanger({ message: ['Order.ItemPage.invalidEtrToast'] });
            } else {
              dispatch(ConfirmOrder(id!, etr));
            }
          }}>
          <p>{t("Order.ItemPage.confirmDialogBody")}</p>
          <NumericField
            selectAllOnFocus
            className={styles.etrField}
            name='etr'
            label={["Order.ItemPage.confirmDialogEtrField"]}
            value={etr}
            onChange={({ target: { value } }) => {
              setEtr(value || 0);
            }}
          />
        </Alert>
        <Alert
          isOpen={isEditEtrDialogVisible}
          confirmButtonText={['save']}
          cancelButtonText={['back']}
          icon="endorsed"
          intent="success"
          loading={confirmState.isLoading}
          onCancel={() => showEditEtrDialog(false)}
          onConfirm={() => {
            if (etr < 0) {
              Toast.showDanger({ message: ['Order.ItemPage.invalidEtrToast'] });
            } else {
              dispatch(EditEtrAction(id!, etr + 1));
              showEditEtrDialog(false);
            }
          }}>
          <p>{t("Order.ItemPage.editEtrDialogBody")}</p>
          <NumericField
            selectAllOnFocus
            className={styles.etrField}
            name='etr'
            label={["Order.ItemPage.editEtrDialogEtrField"]}
            value={etr}
            onChange={({ target: { value } }) => {
              setEtr(value || 0);
            }}
          />
        </Alert>
      </PageContent >
    </>
  );
}